import "@fontsource/roboto-condensed";
import * as React from "react";
import { HeadFC } from "gatsby";
import {
  Box,
  Center,
  Icon,
  Image,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import Layout from "../components/Layout";
import AbhinavImage from "../images/abhinav-hero-img.webp";
import Signature from "../images/Signature.png";
import Arrow from "../images/arrow.png";
import {
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { GrMail } from "react-icons/gr";

const Home = ({}) => {
  const toast = useToast();
  const styles = "font-size: 1.25em; font-weight: bold; padding: 5px;";
  console.group(
    `%c👨‍💻 Hi, I'm Abhinav Patel and Thanks for checking out my Portfolio!`,
    styles
  );
  console.log("%c📺 YouTube - https://www.YouTube.com/@KnackOfAbhinav", styles);
  console.log("%c🐦Twitter - https://twitter.com/KnackOfAbhinav", styles);
  console.log("%c📁 Github - https://github.com/KnackOfAbhinav", styles);
  console.log(
    "%c📝 LinkedIn - https://www.linkedin.com/in/KnackOfAbhinav/",
    styles
  );
  console.log("%c📧 Email - KnackOfAbhinav@gmail.com", styles);
  console.groupEnd();
  return (
    <Layout withTrasparentNav>
      <Image
        position={"absolute"}
        display={["flex", "flex", "none"]}
        zIndex={2}
        bottom={0}
        right={0}
        maxW={["90%", "80%", "0"]}
        src={AbhinavImage}
        mb={0}
        pt={20}
        alt="Abhinav's Image"
      />
      <Box
        alignItems={"center"}
        // display={["none", "none", "flex"]}
        display={"flex"}
        position={"relative"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        h={"100vh"}
        zIndex={3}
      >
        <Box
          w={["100%", "50%"]}
          pt={"20vh"}
          pb={5}
          px={6}
          justifyContent={"space-between"}
          alignItems={"space-between"}
          display={"flex"}
          flexDir={"column"}
          flexGrow={1}
          h={"100vh"}
        >
          <Stack
            spacing={2}
            alignItems={"center"}
            display={"flex"}
            flexDir={"column"}
            flexGrow={1}
          >
            <Text
              fontSize={["12vw", "8vw", "6vw"]}
              color={"white"}
              borderBottom={"8px"}
              borderBottomRadius={5}
              borderColor={"#E4B18E"}
            >
              Abhinav Patel
            </Text>

            <Text
              fontSize={["6vw", "4vw", "2.5vw"]}
              color={"white"}
              textAlign={"center"}
            >
              Building the products you love to use
            </Text>
            <Box
              // w={"50%"}
              bg={
                "linear-gradient(65.45deg, rgba(217, 217, 217, 0.098) 15.87%, rgba(255, 255, 255, 0.1505) 62.18%);"
              }
              display={["none", "none", "flex"]}
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"2xl"}
              onClick={() => {
                navigator.clipboard.writeText("npx knackofabhinav");
                toast({
                  title: "Copied!",
                  description: "We've copied the command to your clipboard!",
                  status: "info",
                  duration: 2000,
                  isClosable: false,
                  position: "bottom-right",
                  variant: "left-accent",
                });
              }}
              cursor={"pointer"}
            >
              <Text
                textAlign={"center"}
                color={"white"}
                py={"0.5vw"}
                px={"1vw"}
                fontSize={["2xl", "3xl", "2.5vw"]}
              >
                npx knackofabhinav
              </Text>
            </Box>
            <Box
              display={["none", "none", "flex"]}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text
                textAlign={"center"}
                color={"white"}
                fontSize={["2xl", "3xl", "2.5vw"]}
                mt={[5, 10, 50]}
                mr={5}
              >
                If you love cmd
              </Text>
              <Image src={Arrow} w={["10%", "20%", ""]} alt="Arrow Image" />
            </Box>
          </Stack>
          <Center
            maxW={["100%", "80%", "50%"]}
            alignSelf={"center"}
            justifyContent={"space-around"}
            w={["100vw", "100vw", ""]}
          >
            <a href={"https://www.github.com/knackofabhinav"} target={"_blank"}>
              <Icon
                as={FaGithub}
                _hover={{ color: "#E4B18E" }}
                transition={"all .4s ease"}
                color={"white"}
                w={8}
                h={8}
                mx={"1vw"}
              />
            </a>
            <a
              href={"https://www.instagram.com/knackofabhinav"}
              target={"_blank"}
            >
              <Icon
                as={FaInstagram}
                _hover={{ color: "#E4B18E" }}
                transition={"all .4s ease"}
                color={"white"}
                w={8}
                h={8}
                mx={"1vw"}
              />
            </a>
            <a
              href={"https://www.twitter.com/knackofabhinav"}
              target={"_blank"}
            >
              <Icon
                as={FaTwitter}
                _hover={{ color: "#E4B18E" }}
                transition={"all .4s ease"}
                color={"white"}
                w={8}
                h={8}
                mx={"1vw"}
              />
            </a>
            <a
              href={"https://www.linkedin.com/in/knackofabhinav"}
              target={"_blank"}
            >
              <Icon
                as={FaLinkedin}
                _hover={{ color: "#E4B18E" }}
                transition={"all .4s ease"}
                color={"white"}
                w={8}
                h={8}
                mx={"1vw"}
              />
            </a>
            <a
              href={"https://www.youtube.com/@knackofabhinav"}
              target={"_blank"}
            >
              <Icon
                as={FaYoutube}
                _hover={{ color: "#E4B18E" }}
                transition={"all .4s ease"}
                color={"white"}
                w={8}
                h={8}
                mx={"1vw"}
              />
            </a>
            <a href={"mailto:knackofabhinav@gmail.com"}>
              <Icon
                as={GrMail}
                color={"white"}
                _hover={{ color: "#E4B18E" }}
                transition={"all .4s ease"}
                w={8}
                h={8}
                mx={"1vw"}
              />
            </a>
          </Center>
        </Box>
        <Box
          alignItems={"flex-end"}
          display={["none", "none", "flex"]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          h={"100vh"}
          pt={"20vh"}
        >
          <Image zIndex={1} src={AbhinavImage} mb={0} alt="Abhinav's Image" />
          <Image
            position={"absolute"}
            maxW={"sm"}
            bottom={5}
            right={5}
            zIndex={1}
            src={Signature}
            mb={0}
            alt="Abhinav's Signature"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Home;

export const Head: HeadFC = () => <title>Home - Abhinav Patel</title>;
